import * as echarts from 'echarts';

export default {
  methods: {
    donutChart(element, title, sub_title, data) {
      // Create the echarts instance
      var myChart = echarts.init(document.getElementById(element));

      // Draw the chart
      myChart.setOption({
        title: {
          text: title,
          left: 'left',
          top: 'top',
          subtext: sub_title
        },
        tooltip: {
          trigger: 'item',
          formatter: '{b}: {c} ({d}%)' // '{b}' es el nombre, '{c}' es el valor, '{d}' es el porcentaje
        },
        color: ['#2981ef', '#cdcdcd', '#181818', '#deedff'],
        series: [
          {
            type: 'pie',
            data,
            radius: ['40%', '70%']
            // label: {
            //   formatter: '{b}: {c} ({d}%)' // '{b}' es el nombre, '{c}' es el valor, '{d}' es el porcentaje
            // }
          }
        ],
        textStyle: {
          fontFamily: "Manrope, sans-serif"
        },
      });
    }
  },
};
