import { controllers } from "chart.js";
import { Doughnut, mixins } from "vue-chartjs";
// import globalOptionsMixin from "@/components/Charts/globalOptionsMixin";

export default {
  name: "doughnut-chart",
  extends: Doughnut,
  mixins: [mixins.reactiveProp],
  props: [
    'chartData',
    'labels'
  ],
  // props: [
  //   {
  //     chartData: {
  //       type: Array || Object,
  //       default: null
  //     },
  //     labels: {
  //       type: Array,
  //       default: null
  //     }
  //   }
  // ],
  mounted() {
    this.renderChart(
      {
        labels: this.labels,
        datasets: [
          {
            label: "Dataset 1",
            weight: 0.5,
            data: this.chartData,
            backgroundColor: ["#F2F2F2", "#cdcdcd", "#b2b2b2", "#2981EF", "#333333", "#767676"]
          }
        ]
      },
      { responsive: true, cutoutPercentage: 40 }
    );
  },

  watch: {
    chartData() {
      this.$data._chart.update();
    }
  }
};
