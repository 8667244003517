<template>
  <section class="dashboard">
    <ValidatePlatform />
    <ValidateDefaulter />
    <div class="container">
      <div class="row justify-content-center">
        <div class="grid-container">
          <div class="grid-item item1">
            <LiveFeed :key="selected_bot.id" />
          </div>
          <div class="grid-item item2">
            <label class="mb-3">Número de nuevos clientes (leads)</label>
            <div class="row">
              <div class="col-6 pl-0">
                <p class="mb-2">Mensual</p>
                <div class="number_box mr-2">
                  <p class="number">
                    {{ showData.monthly ? showData.monthly.leads : 0 }}
                  </p>
                </div>
              </div>
              <div class="col-6 pr-0">
                <p class="mb-2">Hoy</p>
                <div class="number_box destacado_box mr-2">
                  <p class="number destacado_number">
                    {{ showData.daily ? showData.daily.leads : 0 }}
                  </p>
                </div>
              </div>
            </div>
          </div>
          <div class="grid-item item3">
            <label class="mb-3">Número de conversaciones del chatbot</label>
            <div class="row">
              <div class="col-6 pl-0">
                <p class="mb-2">Mensuales</p>
                <div class="number_box mr-2">
                  <p class="number">
                    {{ showData.monthly ? showData.monthly.conversations : 0 }}
                  </p>
                </div>
              </div>
              <div class="col-6 pr-0">
                <p class="mb-2">Hoy</p>
                <div class="number_box destacado_box mr-2">
                  <p class="number destacado_number">
                    {{ showData.daily ? showData.daily.conversations : 0 }}
                  </p>
                </div>
              </div>
            </div>
          </div>
          <div class="grid-item item4">
            <label class="mb-3"
              >Mayor cantidad de leads en año {{ actual_date.getFullYear() }}
            </label>
            <div class="row">
              <div class="col-12 px-0">
                <p class="mb-2">
                  {{ betterResults.label || "Sin datos" }}
                </p>
                <div class="number_box destacado_box mr-2">
                  <p class="number destacado_number">
                    {{ betterResults.data || 0 }}
                  </p>
                </div>
              </div>
            </div>
          </div>
          <div class="grid-item item5 no_card">
            <div class="mt-3">
              <line-chart
                v-if="dataReady"
                :key="selected_bot.id"
                class="grafico"
                :chart-data="datacollection"
                :labels="labels"
                :height="120"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
    <DashboardEcommerce v-if="statisticsEcommerce" :dashboard="true" />
    <div class="container">
      <div class="banner_box">
        <a href="https://solucion.databot.cl/referidos" target="_blank">
          <img class="banner my-4" src="/img/banners/banner-referidos-2.png" alt="referidos">
        </a>
        <a href="https://meetings.hubspot.com/matias-gaete/plan-anual" target="_blank">
          <img class="banner my-4" src="/img/banners/banner-beneficios.png" alt="referidos">
        </a>
      </div>
    </div>
  </section>
</template>

<script>
import moment from "moment";
import { mapState, mapMutations } from "vuex";
import LineChart from "@/components/Charts/LineChart.js";
import dashboard_api from "@/dashboard_api.js";
import ValidatePlatform from "@/components/ValidatePlatform";
import ValidateDefaulter from "@/components/ValidateDefaulter";
import DashboardChart from "@/components/DashboardChart";
import DashboardEcommerce from "@/components/DashboardEcommerce";
import DashboardCircleChart from "@/components/DashboardCircleChart";
import DashboardMixChart from "@/components/DashboardMixChart";
import LiveFeed from "@/components/Dashboard/LiveFeed";

export default {
  components: {
    LineChart,
    LiveFeed,
    DashboardChart,
    DashboardCircleChart,
    DashboardMixChart,
    ValidatePlatform,
    ValidateDefaulter,
    DashboardEcommerce
  },
  data() {
    return {
      datacollection: {
        leads: [],
        conversations: []
      },
      labels: [],
      data: [],
      showData: [],
      currentPage: 1,
      perPage: 5,
      selected: 1,
      leads_total: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
      conversation_total: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
      actual_date: 0,
      dataReady: false,
      // upgrade plan
      my_register: {},
      betterResults: {},
      queries: this.$route.query,
      providerSite: "",
      statisticsEcommerce: false
    };
  },
  created() {
    window.scrollTo(0, 0);
    const self = this;
    self.actual_date = new Date();
    self.cleanRegisterData();
    self.cleanCheckoutData();

    if (!self.logged == "true" || self.user.id == 0) {
      self.$swal({
        icon: "info",
        title: "Aquí puedes entrar a tu dashboard",
        timer: 2000,
        timerProgressBar: true,
        showConfirmButton: false
      });
      self.$router.push("/login");
    }

    if (this.queries && this.queries.status == "successCreditCard") {
      this.$swal({
        icon: "success",
        title: "Tarjeta agregada con éxito"
      });
    }

    if (this.queries && this.queries.status == "failureCreditCard") {
      this.$swal({
        icon: "error",
        title: "Ha ocurrido un problema con la tarjeta",
        text: "Tarjeta rechazada"
      });
    }
    this.getProviderCredential();
  },

  methods: {
    ...mapMutations([
      "cleanRegisterData",
      "cleanCheckoutData",
      "setRegisterProcess",
      "toggleSkipGuide"
    ]),

    show() {
      this.toggleSkipGuide();
    },

    async getBetterResult(bot) {
      if (bot && bot.id) {
        this.betterResults = (await dashboard_api.get(`/dashboards/better_result/${bot.id}`)).data;
      }
    },

    getLeads(bot) {
      const self = this;
      // console.log('bot->', bot);
      const date = moment().format("YYYY-MM");

      if (bot && bot.id) {
        dashboard_api
          .post("/dashboards/current_month_data", {
            dashboard: {
              period: date,
              bot_id: bot.id
            },
            owner: self.active_view
          })
          .then(({ data }) => {
            self.showData = data;

            // console.log('self.showData->', self.showData);
          })
          .catch(error => console.log("error -> ", error));
      }
    },
    // callRealTimeData(bot) {
    //   setInterval(() => {
    //     this.fillData(bot);
    //   }, 900000);
    // },
    fillData(bot) {
      const self = this;
      self.dataReady = false;

      if (bot && bot.id) {
        dashboard_api
          .post("/dashboards/six_month_chart_info", {
            bot_id: bot.id,
            owner: self.active_view
          })
          .then(response => {
            self.labels = response.data.labels;
            self.datacollection = response.data.data;
            self.dataReady = true;
          })
          .catch(error => console.log("error -> ", error));
      }
    },
    async getProviderCredential() {
      try {
        let resp = (
          await dashboard_api.get(`/user_credentials/search_for_bot/${this.selected_bot.id}`, { headers: { BOT_TOKEN: this.selected_bot.token, BOT_ID: this.selected_bot.id } })
        ).data;
        resp.length > 0 ? (this.providerSite = resp[0].provider) : (this.providerSite = false);
        if (this.providerSite) {
          switch (this.providerSite) {
            case "vtex":
              this.statisticsEcommerce = true;
              break;
            case "shopify":
              this.statisticsEcommerce = true;
              break;
            case "woocommerce":
              if (this.selected_bot.id != 756) {
                this.statisticsEcommerce = true;
              }
              break;
            default:
              break;
          }
        } else {
          this.statisticsEcommerce = false;
        }
      } catch (error) {
        console.log("PROVIDER WRONG", error);
      }
    }
  },
  computed: {
    ...mapState(["logged", "user", "active_view", "selected_bot", "platform_name", "skip"])
  },
  watch: {
    selected_bot: {
      immediate: true,
      handler(bot) {
        this.getLeads(bot);
        this.fillData(bot);
        this.getBetterResult(bot);
        // this.callRealTimeData(bot);
      }
    }
  }
};
</script>

<style lang="scss" scoped>
.dashboard {
  margin: 1rem 0 5rem;

  .small {
    width: auto;
    margin: auto;

    .grafico {
      font-family: "Manrope", "sans-serif" !important;
      color: #767676;
    }
  }

  .grid-container {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr;
    grid-gap: 20px;

    @media (max-width: 1400px) {
      margin: auto 1rem;
      grid-gap: 15px;
    }
    .grid-item {
      background-color: #fff;
      border: 1px solid #e8e8e8;
      border-radius: 1rem;
      padding: 1.5rem;
      animation: fadeIn 1s;
    }
    .item1 {
      grid-column: 1;
      grid-row: 1 / span 2;
      @media (max-width: 1279px) {
        grid-column: 1 / 5;
      }
    }
    .item2 {
      grid-column: 2;
      grid-row: 1;

      @media (max-width: 1279px) {
        grid-column: 1 / 3;
        grid-row: 3;
      }
      @media (max-width: 480px) {
        grid-column: 1 / 5;
        grid-row: 3;
      }
    }
    .item3 {
      grid-column: 3;
      grid-row: 1;

      @media (max-width: 1279px) {
        grid-column: 3 / 5;
        grid-row: 3;
      }
      @media (max-width: 480px) {
        grid-column: 1 / 5;
        grid-row: 4;
      }
    }
    .item4 {
      background-color: #fafafa;
      box-shadow: none;
      grid-column: 4;
      grid-row: 1;

      @media (max-width: 1279px) {
        grid-column: 4;
        grid-row: 4;
      }
      @media (max-width: 1120px) {
        grid-column: 1 / 5;
        grid-row: 5;
      }
      @media (max-width: 480px) {
        grid-column: 1 / 5;
        grid-row: 6;
      }
    }
    .item5 {
      grid-column: 2 / span 3;
      grid-row: 2;

      @media (max-width: 1279px) {
        grid-column: 1 / 4;
        grid-row: 4;
      }
      @media (max-width: 1120px) {
        grid-column: 1 / 5;
      }
      @media (max-width: 480px) {
        grid-column: 1 / 5;
        grid-row: 5;
      }
    }
    .no_card {
      background-color: transparent;
      box-shadow: none;
      border: none;
      padding: 0;
    }
    .fa-circle {
      color: #2981ef;
      font-size: 0.75rem;
      margin-top: 0.25rem;
      margin-right: 0.5rem;
    }
    .title,
    label {
      margin-bottom: 0;
    }
  }
  .md {
    @media (max-width: 480px) {
      width: 100%;
    }
  }
  .banner {
    width: 100%;
    cursor: pointer;
    transition: 0.25s;

    &:hover {
      transform: scale(1.025);
      filter: drop-shadow(10px 10px 20px #DEEDFF);
    }
  }
  .banner_box {
    display: flex;
    align-items: center;
    width: 100%;

    @media (max-width: 1200px) {
      padding: 0 1rem;
    }
    @media (max-width: 991px) {
      flex-wrap: wrap;
      margin: 1rem;
    }
    img {
      width: 100%;
    }
  }
}
@keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
</style>
