<template>
  <section class="dashboard-circle-chart">
    <div class="container">
      <div class="row m-row align-items-center my-5">
        <h2 class="title">
          Estados de leads
        </h2>
        <!-- <div class="d-flex align-items-center">
          <p class="mb-0 mr-2">Filtrar datos por:</p>
          <select class="mb-0" placeholder="Filtrar">
            <option value="3">Mes</option>
            <option value="2">Semana</option>
            <option value="1">Hoy</option>
          </select>
        </div> -->
      </div>
      <div class="grid-container">
        <div class="grid-item item1">
          <div class="row align-items-center justify-content-between">
            <div class="d-flex">
              <i class="fas fa-circle" />
              <label class="title">
                Total de estados de leads en CRM
              </label>
            </div>
          </div>
          <br />
          <div class="row align-items-center justify-content-between info">
            <label>Nuevo</label>
            <div class="number_box destacado_box">
              <p class="number destacado_number" v-if="leads">{{ leads['Pendiente'] || 0 }}</p>
            </div>
          </div>
          <div class="row align-items-center justify-content-between info">
            <label>En proceso</label>
            <div class="number_box">
              <p class="number destacado_number" v-if="leads">{{ leads['Contactado'] || 0 }}</p>
            </div>
          </div>
          <div class="row align-items-center justify-content-between info">
            <label>Con venta</label>
            <div class="number_box">
              <p class="number" v-if="leads">{{ leads['Venta Cerrada'] || 0 }}</p>
            </div>
          </div>
          <div class="row align-items-center justify-content-between info">
            <label>Sin venta</label>
            <div class="number_box">
              <p class="number" v-if="leads">{{ leads['perdido'] || 0 }}</p>
            </div>
          </div>
          <div class="row mt-5 mb-0 justify-content-center">
            <router-link
              class="btn btn-base outline sm"
              :to="{ name: 'crm' }"
              >Ver crm
            </router-link>
          </div>
        </div>
        <div class="grid-item item2 no_card">
          <div class="row align-items-center justify-content-between mt-3 mb-4">
            <div class="col-lg-6 justify-content-center m-auto">
              <div class="row align-items-center mb-5">
                <div class="d-flex">
                  <i class="fas fa-circle" />
                  <label class="title">
                    Cantidad de estados del chatbot
                  </label>
                </div>
              </div>
              <apexchart
                type="donut"
                height="300"
                :options="chartOptions"
                :series="series"
                :key="series[0] || 0"
              ></apexchart>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import { mapState } from 'vuex';
import VueApexCharts from 'vue-apexcharts';
import DoughnutChart from '@/components/Charts/DoughnutChart.js';
import dashboard_api from '@/dashboard_api';

export default {
  components: {
    DoughnutChart,
    apexchart: VueApexCharts,
  },
  data() {
    return {
      leads: null,
      data: null,
      labels: [],
      filter: '3',
      series: [],
      chartOptions: {
        chart: {
          type: 'donut',
          redrawOnParentResize: true,
          size: '5%',
          fontFamily: 'Quicksand, sans-serif',
          foreColor: '#767676',
        },
        plotOptions: {
          bar: {
            borderRadius: 4,
            horizontal: true,
            fontFamily: 'Quicksand, sans-serif',
            foreColor: '#767676',
          },
          pie: {
            expandOnClick: true,
          },
        },
        dataLabels: {
          enabled: false,
        },
        labels: [],
        noData: {
          text: 'Sin información',
        },
        fill: {
          colors: ['#2981ef', '#cdcdcd', '#333333', '#f2f2f2', '#b2b2b2'],
        },
        legend: {
          markers: {
            fillColors: ['#2981ef', '#cdcdcd', '#333333', '#f2f2f2', '#b2b2b2'],
          }
        },
        tooltip: {
          enabled: false,
        }
      },
    };
  },

  created() {
    this.get_data();
  },

  computed: {
    ...mapState(['bannerSelectedBot']),
  },

  methods: {
    get_data() {
      dashboard_api
        .get(`/dashboards/lead_states/${this.bannerSelectedBot.id}/${this.filter}`)
        .then((result) => {
          this.leads = result.data.leads;

          Object.keys(this.leads).forEach((key) => {
            this.labels.push(this.replaceKey(key));
            this.series.push(this.leads[key]);
          });

          this.data = result.data.data;
          this.chartOptions.labels = this.labels;
        })
        .catch((error) => console.log(error));
    },

    replaceKey(key) {
      switch (key) {
        case 'Venta Cerrada':
          return 'Con venta';
          break;
        case 'perdido':
          return 'Sin venta';
          break;
        case 'Contactado':
          return 'En proceso';
          break;
        case 'Pendiente':
          return 'Nuevo';
          break;
      }
    },
  },

  watch: {
    filter(value) {
      this.get_data();
    },
  },
};
</script>

<style lang="scss" scoped>
.dashboard-circle-chart {
  margin: 0 auto 4rem;

  .m-row {
    @media (max-width: 1400px) {
      margin-left: 15px;
      margin-right: 15px;
    }
  }
  .grid-container {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr;
    grid-gap: 20px;

    @media (max-width: 1400px) {
      margin: auto 1rem;
      grid-gap: 15px;
    }
    .grid-item {
      border: 1px solid #e8e8e8;
      background-color: #fff;
      border-radius: 1rem;
      padding: 1.5rem;
    }
    .item1 {
      grid-column: 1;
      grid-row: 1;

      @media (max-width: 1279px) {
        grid-column: 1 / 5;
      }
    }
    .item2 {
      grid-column: 2 / 5;
      grid-row: 1;

      @media (max-width: 1279px) {
        grid-column: 1 / 5;
        grid-row: 2;
      }
    }
    .no_card {
      background-color: transparent;
      border: none;
      padding: 0;
    }
  }
  .fa-circle {
    color: #2981ef;
    font-size: 0.75rem;
    margin-top: 0.25rem;
    margin-right: 0.75rem;
  }
  .title, label {
    margin-bottom: 0;
  }
  .md {
    width: 100%;
  }
  .card {
    width: 100%;
    height: 100%;
  }
}
</style>
