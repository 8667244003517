import { Line, mixins } from 'vue-chartjs';

export default {
  extends: Line,
  mixins: [mixins.reactiveProp],
  props: [
    'chartData',
    'labels'
  ],
  // props: [
  //   {
  //     chartData: {
  //       type: Array || Object,
  //       default: null,
  //     },
  //     labels: {
  //       type: Array,
  //       default: null,
  //     },
  //   }
  // ],
  mounted() {
    this.gradient = this.$refs.canvas
      .getContext('2d')
      .createLinearGradient(0, 0, 0, 450);

    this.gradient.addColorStop(0, 'rgba(255, 0,0, 0.5)');
    this.gradient.addColorStop(0.5, 'rgba(255, 0, 0, 0.25)');
    this.gradient.addColorStop(1, 'rgba(255, 0, 0, 0)');

    this.renderChart(
      {
        labels: this.labels,
        datasets: [
          {
            label: 'Leads',
            borderColor: '#2981ef',
            pointBackgroundColor: '#2981ef',
            backgroundColor: 'transparent',
            borderWidth: 2,
            pointBorderColor: '#2981ef',
            data: this.chartData.leads,
          },
          {
            label: 'Conversaciones',
            borderColor: '#333333',
            pointBackgroundColor: '#333333',
            backgroundColor: 'transparent',
            borderWidth: 2,
            pointBorderColor: '#333333',
            data: this.chartData.conversations,
          },
        ],
      },
      { responsive: true },
    );
  },
};
