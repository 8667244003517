import * as echarts from 'echarts';

export default {
  methods: {
    barChart(data, element, title) {
      let chartDom = document.getElementById(element);
      var myChart = echarts.init(chartDom, null, {
        renderer: 'svg',
      });

      myChart.setOption({
        title: {
          text: title,
        },
        legend: {},
        tooltip: {},
        xAxis: { type: 'category' },
        yAxis: {},
        toolbox: {
          feature: {
            saveAsImage: {},
            magicType: {
              type: ['line', 'bar', 'stack'],
            },
          },
        },
        dataset: {
          dimensions: data.dimensions,
          source: [data.data],
        },
        series: [
          { type: 'bar', itemStyle: { color: "#767676" } }, { type: 'bar', itemStyle: { color: "#2981EF" } }, {
        }],
        textStyle: {
          fontFamily: "Manrope, sans-serif"
        }
      });
    }
  },
};
