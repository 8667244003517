import * as echarts from 'echarts';

export default {
  methods: {
    lineChart(labels, data, element) {
      // Create the echarts instance
      var myChart = echarts.init(document.getElementById(element));

      // Draw the chart
      myChart.setOption({
        title: {
          text: 'ECharts Getting Started Example',
        },
        tooltip: {},
        xAxis: {
          data: ['shirt', 'cardigan', 'chiffon', 'pants', 'heels', 'socks'],
        },
        yAxis: {},
        toolbox: {
          feature: {
            saveAsImage: {},
          },
        },
        series: [
          {
            name: 'sales',
            type: 'bar',
            data: [5, 20, 36, 10, 10, 20],
          },
        ],
        textStyle: {
          fontFamily: "Manrope, sans-serif"
        }
      });
    }
  },
};
