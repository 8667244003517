<template>
  <section class="dashboard-mix-chart">
    <div class="container">
      <div class="row align-items-center justify-content-between mx-2 my-5">
        <div class="d-flex">
          <i class="fas fa-circle" />
          <h2 class="title">
            Tiempo medio de conversaciones intervenidas
          </h2>
        </div>
        <!-- <div class="d-flex align-items-center">
          <p class="mb-0 mr-2">Filtrar datos por:</p>
          <select placeholder="Filtrar">
            <option value="">Mes</option>
            <option value="">Semana</option>
            <option value="">Hoy</option>
          </select>
        </div> -->
      </div>
      <div class="grid-container">
        <div class="grid-item item1 no_card">
          <LineChart
            :chart-data="[]"
            :labels="[]"
            :height=150
          />
        </div>
        <div class="grid-item item2 no_card">
          <div class="row align-items-center justify-content-between">
            <div class="d-flex mb-5">
              <i class="fas fa-circle" />
              <label class="title">
                Promedio de Leads por canal
              </label>
            </div>
          </div>
          <DoughnutChart :height="300" />
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import DoughnutChart from '@/components/Charts/DoughnutChart.js';
import LineChart from '@/components/Charts/LineChart.js';

export default {
  components: {
    DoughnutChart,
    LineChart,
  },
  props: [
    'props__datacollection',
    'props__labels',
    'props__height',
  ],
  data() {
    return {
    };
  },
};
</script>

<style lang="scss" scoped>
.dashboard-mix-chart {
  margin: 4rem auto;

  .grid-container {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr;
    grid-gap: 20px;

    @media (max-width: 1400px) {
      margin: auto 1rem;
      grid-gap: 15px;
    }
    .grid-item {
      background-color: #fff;
      box-shadow: 0 0 30px rgba(0, 0, 0, .1);
      border-radius: 1rem;
      padding: 1.5rem;
    }
    .item1 {
      grid-column: 1 / 4;
      grid-row: 1;
    }
    .item2 {
      grid-column: 4;
      grid-row: 1;
    }
    .no_card {
      background-color: transparent;
      box-shadow: none;
      padding: 0;
    }
  }
  .fa-circle {
    color: #2981EF;
    font-size: .75rem;
    margin-top: .25rem;
    margin-right: .75rem;
  }
  .title {
    margin-bottom: 0;
  }
  .container {
    padding: 0;
  }
  .number_box {
    display: inline-block;
    text-align: center;
    width: auto;
    padding: .5rem 1rem;
    margin-right: .75rem;
    border-radius: 8px;
    background-color: #f2f2f2;

    .number {
      color: #181818;
      font-weight: 700;
      margin: 0;
    }
  }
  .destacado_box {
    background-color: #2981EF;

    .destacado_number {
      color: #f2f2f2;
    }
  }
  label {
    margin-bottom: 0;
  }
  .lg {
    width: 100%;
  }
  .btn-add {
    border: none;
    border-radius: 8px;
    margin: 1rem auto;
    padding: .5rem 1rem;
    background-color: #fff;
    box-shadow: 0 0 10px rgba(0, 0, 0, .15);
    transition: .5s;

    &:hover {
      color: #fff;
      background-color: #2981EF;
      box-shadow: none;
    }
  }
}
</style>
