<template>
  <section class="live-feed">
    <div class="row align-items-center justify-content-between">
      <div class="d-flex">
        <i class="fas fa-circle" />
        <label>Conversaciones en vivo</label>
      </div>
      <router-link
        :to="{ name: 'databot-live' }"
        class="btn btn-base outline mx-0 mobile"
        >Ver databot live
      </router-link>
    </div>
    <p class="pl-3">Nuevos clientes obtenidos este mes.</p>
    <br />
    <div class="grid_tablet grid_mobile">
      <div class="info">
        <div class="number_box destacado_box">
          <p class="number destacado_number">{{ realTimeData.waitting ? realTimeData.waitting : 0 }}</p>
        </div>
        <label>Conversaciones en espera</label>
      </div>
      <span
        @click="missedCalls"
        class="info click">
        <div class="number_box">
          <p class="number">{{ realTimeData.not_answered ? realTimeData.not_answered : 0 }}
          </p>
        </div>
        <label>Conversaciones perdidas</label>
      </span>
      <span
        @click="throwCalls"
        class="info click">
        <div class="number_box">
          <p class="number">{{ realTimeData.answered || 0 }}
          </p>
        </div>
        <label>Conversaciones con agente</label>
      </span>
       <div class="info_box">
         <p class="mb-0"><i class="fas fa-info-circle" /> Puedes revisar tus conversaciones intervenidas desde databot live.</p>
       </div>
      <div class="row justify-content-center mt-4">
        <router-link
          :to="{ name: 'databot-live' }"
          class="btn btn-base outline my-0 desktop"
          >Ver databot live
        </router-link>
      </div>
    </div>
    <template v-if="selected_bot">
      <LlamadasPerdidas
        :showModal="showCalls"
        @closeModal="showCalls = false"
        @back="showCalls = false"
        :data="missed"
      />
      <LlamadasContestadas
        :showModal="showAnswerCalls"
        @closeModal="showAnswerCalls = false"
        @back="showAnswerCalls = false"
        :data="attended"
      />
    </template>
  </section>
</template>

<script>
import { mapState } from 'vuex';
import dashboard_api from '@/dashboard_api.js';
import LlamadasPerdidas from './components/LlamadasPerdidas';
import LlamadasContestadas from './components/LlamadasContestadas';

export default {
  components: {
    LlamadasPerdidas,
    LlamadasContestadas
  },
  data() {
    return {
      showCalls: false,
      showAnswerCalls: false,
      realTimeData: [],
      missed: [],
      attended: [],
    };
  },

  watch: {
    selected_bot: {
      immediate: true,
      handler(val) {
        this.getRealTimeData(val);
        // TODO Comento esto mientras, la idea es que solo se ejecute cuando se está en el dashboard
        // this.callRealTimeData(val);
      }
    }
  },
  computed: {
    ...mapState(['selected_bot']),
  },
  methods: {
    async throwCalls() {
      this.attended = (await dashboard_api.get(`/dashboards/attended_chats/${this.selected_bot.id}`)).data.data;

      console.log('Resp attended ->', this.attended);
      this.showAnswerCalls = true;
    },
    async missedCalls() {
      this.missed = (await dashboard_api.get(`/dashboards/missed_chats/${this.selected_bot.id}`)).data.data;

      console.log('Resp missed ->', this.missed);
      this.showCalls = true;
    },
    async getRealTimeData(bot) {
      if (bot && bot.id) {
        dashboard_api
          .post('/dashboards/real_time_data', {
            bot_id: bot.id,
          })
          .then((rtResponse) => {
            this.realTimeData = rtResponse.data;
          })
          .catch((error) => console.log('error -> ', error));
      }
    },

    callRealTimeData(bot) {
      if (bot && bot.id) {
        setInterval(() => {
          this.getRealTimeData(bot);
        }, 120000);
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.live-feed {
  position: relative;
  width: 100%;

  .fa-circle {
    color: #2981EF;
    font-size: .75rem;
    margin-top: .25rem;
    margin-right: .5rem;
  }
  label {
    margin-bottom: 0;

    @media (max-width: 1279px) {
      margin-right: 1rem;
    }
  }
  .info {
    align-items: center;
    margin: 0 auto .5rem;
    padding: .75rem;
    display: flex;

    @media (max-width: 1279px) {
      margin: .5rem auto;
    }
    @media (max-width: 1035px) {
      display: block;
    }
    @media (max-width: 480px) {
      margin: 0 auto 1.5rem;
      display: flex;
    }
    .number_box {
      display: inline-block;
      text-align: center;
      width: auto;
      padding: .5rem 1rem;
      margin-right: .75rem;
      border-radius: .5rem;
      border: 1px solid #e8e8e8;
      background-color: #fafafa;
      width: 100px;

      @media (max-width: 1035px) {
        margin-bottom: 1rem;
      }
      @media (max-width: 480px) {
        margin-bottom: 0;
      }
      .number {
        color: #181818;
        font-weight: 700;
        margin: 0;
      }
    }
    .destacado_box {
      border: none;
      background-color: #2981EF;

      .destacado_number {
        color: #f2f2f2;
      }
    }
  }
  .click {
    transition: .5s;

    &:hover {
      background-color: #fafafa;
      border-radius: .5rem;

      .number_box {
        transition: .5s;
        background-color: #333 !important;

        p {
          color: #fff !important;
        }
      }
    }
    .number_box, p, label {
      cursor: pointer;
    }
  }
  .mobile {
    @media (min-width: 1280px) {
      display: none;
    }
  }
  .desktop {
    @media (max-width: 1279px) {
      display: none;
    }
  }
  .grid_tablet {
    @media (max-width: 1279px) {
      display: flex !important;
      align-items: center;
    }
  }
  .grid_mobile {
    @media (max-width: 480px) {
      display: block !important;
    }
  }
  .desktop-lg {
    @media (max-width: 1600px) {
      display: none;
    }
  }
  .info_box {
    padding: 1rem;
    border-radius: .5rem;
    margin: 1rem .75rem 0;

    @media (max-width: 1600px) {
      display: none;
    }
  }
}
</style>
