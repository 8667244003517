import { HorizontalBar, mixins } from "vue-chartjs";

export default {
  extends: HorizontalBar,
  mixins: [mixins.reactiveProp],
  props: {
    chartData: {
      type: Array || Object,
      default: null
    },
    labels: {
      type: Array,
      default: null
    }
  },

  mounted() {
    // this.gradient = this.$refs.canvas.getContext('2d').createLinearGradient(0, 0, 0, 450);

    // this.gradient.addColorStop(0, 'rgba(255, 0,0, 0.5)');
    // this.gradient.addColorStop(0.5, 'rgba(255, 0, 0, 0.25)');
    // this.gradient.addColorStop(1, 'rgba(255, 0, 0, 0)');

    this.renderChart(
      {
        labels: this.labels,
        datasets: [
          {
            label: "Conversaciones",
            // borderColor: '',
            pointBackgroundColor: "#2981ef",
            backgroundColor: ["#cdcdcd", "#2981ef"],
            borderWidth: 1,
            pointBorderColor: "#2981ef",
            data: this.chartData
          }
        ]
      },
      {
        responsive: true,
        indexAxis: "y"
      }
    );
  },

  watch: {
    chartData() {
      this.$data._chart.update();
    }
  }
};
