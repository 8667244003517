<template>
  <section :key="lead.id" class="chat-preview-xl">
    <div class="chat-head">
      <div class="label-box">
        <div>
          <label class="mb-0" for="">Chat de {{ lead.name || "Cliente" }}</label>
        </div>
        <div class="d-flex align-items-center">
          <span class="text_sm mr-2">Canal: {{ setChannelLabel(lead.channel) }}</span>
          <span @click="$emit('close')" class="i-btn i-close i-btn-sm i-sm"></span>
        </div>
      </div>
    </div>
    <SpinnerLoading v-if="loading" />
    <FadeTransition v-else :duration="200">
      <div class="chat-body px-4 w-100">
        <ChatMessages
          v-if="chats && chats.length > 0"
          :user="user"
          :bot="bot"
          :getImgBot="imgBot"
          :messages="chats"
          :modal="lead"
        />
        <div v-else class="no-data">
          <img class="chat-icon mb-3" src="/img/icons/23-no-info.svg" alt="" />
          <span class="text_sm">Este lead no tiene conversación.</span>
        </div>
      </div>
    </FadeTransition>
  </section>
</template>

<script>
import dashboard_api from "@/dashboard_api";
import ChatMessages from '@/components/ChatMessages.vue';
import { FadeTransition } from "vue2-transitions";

export default {
  props: ["lead", "user", "show", "bot"],

  components: {
    ChatMessages,
    FadeTransition
  },
  data() {
    return {
      chats: [],
      imgBot: "",
      loading: false
    }
  },
  watch: {
    lead(val) {
      if (val) {
        this.getChat();
        this.getChatbotInfo();
      }
    }
  },
  methods: {
    setChannelLabel(name) {
      switch (name) {
        case "Chatbot":
          return "Chatbot web"
        case "Whatsapp Bot":
          return "Chatbot WhatsApp"
        case "Manual":
          return "Carga manual"
        case "Carga Masiva":
          return "Carga masiva"
        default:
          return name
      }
    },
    async getChat() {
      if (this.lead.id) {
        this.loading = true;
        let resp = (await dashboard_api.get(`lead/all_data/${this.lead.id}`)).data;
        this.chats = resp.logs;
        this.loading = false;
      }
    },
    async getChatbotInfo() {
      let chatbot = (
        await dashboard_api.get(`/bot/showedit/${this.bot.id}`)
      ).data;

      this.imgBot =
        chatbot.bot_avatar || chatbot.bot_logo_url || 'img/brand/0-default.svg';
    },
  }
}
</script>

<style lang="scss" scoped>
.chat-preview-xl {
  position: absolute;
  top: 0;
  right: 0;
  height: 100vh;
  width: 480px;
  background-color: #fff;
  box-shadow: 0 0 60px rgba(0, 0, 0, 0.2);
  z-index: 100;
  animation-name: test;
  animation-duration: .25s;
  overflow-y: hidden;

  .bg-spinner {
    background-color: #fafafa;
  }
  .chat-head {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 2rem;
    width: 100%;
    background-color: #fafafa;
    height: 80px;
    border-bottom: 1px solid #e8e8e8;
  }
  .chat-body {
    padding: 2rem 0 4rem;
    height: calc(92.25vh);
    overflow-y: scroll;
    background-color: #fff;
  }
  @keyframes test {
    0% {
      opacity: 0;
      transform: translateX(100%);
    }
    100% {
      opacity: 1;
      transform: translateX(0);
    }
  }
  .no-data {
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    .chat-icon {
      width: 100px;
    }
  }
}
</style>