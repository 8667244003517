<template>
  <section class="card">
    <div class="card-head">
      <label class="mb-0">{{
        selected_bot.name
          ? 'Conversación de ' + selected_bot.name
          : 'Historial de conversación'
      }}</label>
    </div>
    <div class="card-body">
      <template v-if="logs && logs.length > 0">
        <ChatMessages
          :user="user"
          :bot="selected_bot"
          :getImgBot="botImg"
          :messages="logs"
          :modal="chat"
        />
      </template>
      <template v-else>
        <div class="no_select d-flex flex-column justify-content-center align-items-center">
          <span class="text_sm"
            >Selecciona una conversación.</span
          >
        </div>
      </template>
    </div>
    <div v-if="logs && logs.length > 0" class="d-flex justify-content-center">
      <button
        @click="exportConversation()"
        class="btn btn-base my-5"
        :disabled="view_permissions.export != true"
      >
        Exportar conversación
        <span class="tooltip_text-sm for_disabled" v-if="view_permissions.export != true">Lo sentimos, no tienes accesos a este módulo.</span>
      </button>
    </div>
  </section>
</template>

<script>
import { mapState } from 'vuex';
import dashboard_api_v2 from '@/dashboard_api_v2';
import ChatMessages from '@/components/ChatMessages.vue';

export default {
  components: {
    ChatMessages,
  },
  props: ['logs', 'chat'],

  data() {
    return {
      loading: false,
    };
  },
  computed: {
    ...mapState(['user', 'selected_bot', 'view_permissions']),

    botImg() {
      return (
        this.selected_bot.bot_avatar ||
        this.selected_bot.bot_logo_url ||
        '/img/brand/0-default.svg'
      );
    },
  },

  methods: {
    exportConversation() {
      this.$swal({
        icon: 'success',
        title: 'La conversación será enviada al correo en unos instantes',
        timer: 1800,
        showConfirmButton: false,
      });
      dashboard_api_v2
        .post('/export/conversation_log_pdf', {
          databot_live_id: this.logs[0].databot_live_id,
          headers: {
            'Content-Type': 'multipart/form-data',
          },
        })
        .catch((error) => console.error('error >', error));
    },
  },
};
</script>

<style lang="scss" scoped>
.card {
  padding: 0 1rem;
  min-height: 500px;
  .card-head {
    padding: 1.5rem 0;
    height: 100%;
    display: flex;
    flex-wrap: wrap;
    align-items: flex-start;
    justify-content: space-between;
    background-color: #fff;
    border-bottom: 1px solid #e8e8e8;
  }
  .card-body {
    position: relative;
    background-color: #fff;
    padding: 2rem 0;
    height: 100%;
    overflow-y: scroll;
    scroll-behavior: smooth;

    &::-webkit-scrollbar {
      width: 7px;
    }
    &::-webkit-scrollbar-thumb {
      visibility: hidden;
      border-radius: 2rem;
      background: #cdcdcd;
      transition: .25s;

      &:hover, &:active {
        visibility: visible;
        background: #afafaf;
      }
    }
  }
  .no_select {
    height: 365px;
  }
}
</style>
