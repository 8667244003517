<template>
  <!-- Horizontal bar chart -->
  <section class="dashboard-chart">
    <div class="container">
      <div class="row m-row align-items-center my-5">
        <h2 class="title">
          Conversaciones intervenidas por agente
        </h2>
        <!-- <div class="d-flex align-items-center">
          <p class="mb-0 mr-2">Filtrar datos por:</p>
          <select placeholder="Filtrar" v-model="filter">
            <option value="3">Mes</option>
            <option value="2">Semana</option>
            <option value="1">Hoy</option>
          </select>
        </div> -->
      </div>
      <div class="grid-container">
        <div class="grid-item item1 no_card">
          <apexchart
            type="bar"
            height="350"
            :options="chartOptions"
            :series="series"
            v-if="data"
          />
          <div v-else class="card d-flex justify-content-center align-items-center h-100">
            <p>Sin conversaciones intervenidas</p>
          </div>
          <!-- <HorizontalBarChart
            :key="filter"
            v-if="data && labels"
            :chart-data="data"
            :labels="labels"
            :height="150"
          /> -->
        </div>
        <div class="grid-item item2">
          <label class="mb-4">Mayor número de intervenciones por:</label>
          <div class="d-flex align-items-center justify-content-between">
            <label>{{ labels && labels[index] ? labels[index] : 'Sin datos' }}</label>
            <div class="number_box destacado_box mx-0">
              <p class="number destacado_number">{{ data && data[index] ? data[index] : 0 }}</p>
            </div>
          </div>
        </div>
        <div class="grid-item item3">
          <div class="d-flex align-items-center mb-4">
            <i class="fas fa-info-circle" />
            <label class="mb-0">Importante</label>
          </div>
          <p class="info mb-0">Intenta mantener un equilibrio entre las conversaciones intervenidas de todos tus agentes.</p>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import { mapState } from "vuex";
import VueApexCharts from 'vue-apexcharts';
import HorizontalBarChart from '@/components/Charts/HorizontalBarChart.js';
import dashboard_api from '@/dashboard_api.js';

export default {
  components: {
    HorizontalBarChart,
    apexchart: VueApexCharts,
  },

  data() {
    return {
      data: null,
      labels: {},
      index: 0,
      filter: '3',
      series: null,
      chartOptions: {
        chart: {
          fontFamily: 'Manrope, sans-serif',
          foreColor: '#767676',
          type: 'bar',
          height: 300,
          redrawOnParentResize: true,
        },
        plotOptions: {
          bar: {
            borderRadius: 4,
            horizontal: true,
          },
        },
        dataLabels: {
          enabled: false,
        },
        xaxis: {
          categories: null,
        },
        fill: {
          colors: ['#2981EF'],
        },
        noData: {
          text: 'Sin información',
        },
      },
    };
  },

  created() {
    this.getInfo(this.selected_bot.id);
  },

  watch: {
    selected_bot(val) {
      this.getInfo(val);
    }
  },

  computed: {
    ...mapState(['selected_bot'])
  },

  methods: {
    async getInfo(bot) {
      if (bot.id) {
        try {
          let resp = (await dashboard_api.get(`/dashboards/agent_conversations/${bot.id}/${this.filter}`)).data;

          this.labels = resp.labels;
          this.data = resp.conversations;
          this.series = [{ name: 'Conversaciones', data: this.data }];
          this.chartOptions.xaxis.categories = this.labels;

          if (this.data > 0) {
            const el = this.data[0];
            this.data.forEach((element) => {
              if (el < element) this.index = this.data.indexOf(element);
            });
          } else this.labels = [];
        } catch (error) {
          console.log(error);
        }
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.dashboard-chart {
  margin: 4rem auto 0;

  .fa-circle {
    color: #2981EF;
    font-size: .75rem;
    margin-top: .5rem;
    margin-right: .75rem;
  }

  select {
    margin: 0;
  }
  .m-row {
    @media (max-width: 1400px) {
      margin-left: 15px;
      margin-right: 15px;
    }
  }
  .grid-container {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr;
    grid-gap: 20px;
    animation: fadeInUp 4s ease backwards;
    // @media (max-width: 1600px) {
    //   grid-gap: 10px;
    // }
    @media (max-width: 1400px) {
      margin: auto 1rem;
      grid-gap: 15px;
    }
    .grid-item {
      background-color: #fff;
      border: 1px solid #e8e8e8;
      border-radius: 1rem;
      padding: 1.5rem;
    }
    .item1 {
      grid-column: 1 / 4;
      grid-row: 1 / 4;

      @media (max-width: 1279px) {
        grid-column: 1 / 5;
        grid-row: 1;
      }
    }
    .item2 {
      grid-column: 4;
      grid-row: 1;

      @media (max-width: 1279px) {
        grid-column: 1 / 3;
        grid-row: 2;
      }
      @media (max-width: 480px) {
        grid-column: 1 / 5;
        grid-row: 2;
      }
    }
    .item3 {
      background-color: #fafafa;
      grid-column: 4;
      grid-row: 2;

      @media (max-width: 1279px) {
        grid-column: 3 / 5;
        grid-row: 2;
      }
      @media (max-width: 480px) {
        grid-column: 1 / 5;
        grid-row: 3;
      }
    }
    .no_card {
      background-color: transparent;
      box-shadow: none;
      border: none;
      padding: 0;
    }
  }
  .number_box {
    display: inline-block;
    text-align: center;
    width: auto;
    padding: .5rem 1rem;
    border-radius: 8px;
    background-color: #f2f2f2;

    .number {
      color: #181818;
      font-weight: 700;
      margin: 0;
    }
  }
  .destacado_box {
    background-color: #2981EF;

    .destacado_number {
      color: #f2f2f2;
    }
  }
  .fa-info-circle {
    color: #2981EF;
    font-size: 1rem;
  }
}

@keyframes fadeInUp {
  0% {
    transform: translate(0px, 100px);
    opacity: 0;
  }
  100% {
    transform: translate(0px, 0);
    opacity: 1;
  }
}
</style>
