<template>
  <Modal
    class="modal-2"
    ancho="600"
    alto="500"
    :show="showModal"
    @close="$emit('closeModal')"
  >
    <template slot="header">
      <label class="mb-4 px-3">Conversaciones contestadas</label>
    </template>
    <template slot="content">
      <section class="llamadas-contestadas">
        <template v-if="data && data.length > 0">
          <div class="col-lg-12 mt-2">
            <p class="px-1">Estos son los clientes que se han intentado contactar con un agente y se les ha contestado.</p>
            <div class="info_box scroll_box bg_grey mb-3">
              <div
                v-for="item in data"
                :key="item.id"
                class="row final-item align-items-center my-2">
                <div class="item px-1">
                  <p>{{ item.lead.name }}</p>
                </div>
                <div class="item2 px-1">
                  <p>{{ item.lead.email }}</p>
                </div>
                <div class="item px-1">
                  <p>{{ item.lead.phone }}</p>
                </div>
                <div class="item px-1">
                  <span class="text-state">{{ item.lead.estado }}</span>
                </div>
              </div>
            </div>
            <div class="col-lg-5 justify-content-center d-flex m-auto">
              <router-link
                class="btn btn-base"
                :to="`/clients`">
                Ver CRM
              </router-link>
            </div>
          </div>
        </template>
        <template v-else>
          <div class="col-lg-12 mt-2">
            <div class="info_box scroll_box bg_grey mb-3">
              <p class="text-center mb-0">No tienes conversaciones contestadas el día de hoy.</p>
            </div>
            <span
              @click="$emit('back')"
              class="btn-text text-center mt-4">
              &lt;- Volver
            </span>
          </div>
        </template>
      </section>
    </template>
  </Modal>
</template>

<script>
export default {
  props: [
    'showModal',
    'closeModal',
    'data',
  ],
};
</script>

<style lang="scss" scoped>
.llamadas-contestadas {
  padding: 1rem;
  background-color: #fff;
  border-radius: 1rem;
  text-align: left;

  .scroll_box {
    max-height: 400px;
    overflow-x: scroll !important;
    flex-grow: 1;
    margin-bottom: 0;

    &::-webkit-scrollbar {
      height: 6px;
    }
    /* Color de fondo y radius esquinas del thumb */
    &::-webkit-scrollbar-thumb {
      margin-bottom: 1rem;
    }
  }
  .btn-text {
    display: block;
  }
  .btn-base {
    margin: 1rem auto;
    width: 100%;
  }
  .info_box {
    font-size: 12px;
    padding: 1rem;
  }
  .item {
    flex: 0 0 20%;
    max-width: 20%;
    margin-bottom: 1rem;
    padding-bottom: 1rem;
    border-bottom: 1px solid #cdcdcd;
  }
  .item2 {
    flex: 0 0 40%;
    max-width: 40%;
    margin-bottom: 1rem;
    padding-bottom: 1rem;
    border-bottom: 1px solid #cdcdcd;
  }
  .final-item {
    &:last-child {
      .item, .item2 {
        margin-bottom: 0;
        padding-bottom: 0;
        border-bottom: none;
      }
    }
  }
}
</style>
