<template>
  <FadeTransition :duration="200">
    <section v-show="selected == 'prestashop'">
      <hr class="my-5" />
      <h3>Integrar PrestaShop</h3>
      <p class="mb-5">Si utilizas otra plataforma para manejar los datos de tus clientes, desde aquí podrás integrarla.</p>
      <p class="text_error" v-if="error == 'error_key'">Completa el usuario</p>
      <label v-else class="mb-2">Api key</label>
      <label
        for="prestashop-api_key"
        class="card card-click mb-5"
        :class="{border_error: error == 'error_key'}"
      >
        <input
          type="text"
          placeholder="Ingresa tu Api key"
          class="no_input"
          :class="{border_error: error == 'error_key'}"
          v-model="prestashop.pkey"
          id="prestashop-api_key"
        />
      </label>
      <p class="text_error" v-if="error == 'error_empty'">Completa el dominio</p>
      <p class="text_error" v-else-if="error == 'error_domain'">Completa el dominio incluyendo http</p>
      <label v-else class="mb-2">Tu dominio</label>
      <label
        for="prestashop-domain"
        class="card card-click mb-5"
        :class="{border_error: error == 'error_domain' || error == 'error_empty'}"
      >
        <input
          type="text"
          placeholder="Ejem: https://tu-dominio.com"
          class="no_input"
          v-model="prestashop.pdomain"
          id="prestashop-domain"
        />
      </label>
      <div class="row justify-content-between">
        <button
          @click="saveIntegration()"
          class="btn btn-base md mx-0"
          :disabled="loading || view_permissions.edit != true"
          >{{ !edit ? 'Guardar' : 'Guardar cambios' }}
          <span class="tooltip_text-sm for_disabled" v-if="view_permissions.edit != true">Lo sentimos, no tienes permisos para guardar cambios.</span>
        </button>
        <button
          class="btn btn-base outline md mx-0"
          @click="$emit('closeView')"
          >Cancelar
        </button>
      </div>
      <hr class="mt-5 mb-3" />
    </section>
  </FadeTransition>
</template>

<script>
import { FadeTransition } from "vue2-transitions";
import dashboard_api from "@/dashboard_api.js";
import environment from '@/environment.js';
import axios from "axios"

export default {
  components: {
    FadeTransition
  },
  props: [
    "selected",
    "bot_id",
    "fields",
    "integrations",
    "view_permissions"
  ],
  data() {
    return {
      edit: false,
      loading: false,
      integrationId: 0,
      credentials: {},
      error: '',
      prestashop: {
        pkey: '',
        pdomain: '',
      }
    };
  },

  watch: {
    selected(val) {
      if (this.integrations && this.integrations.length > 0) {
        for (let integration of this.integrations) {
          if (this.selected == integration.provider) {
            this.edit = true;
            this.integrationId = integration.id;
            this.credentials = integration.credentials;
            this.getCredentials();
          } else {
            this.edit = false;
          }
        }
      }
    },
    fields(val) {
      this.edit = false,
      this.prestashop.pkey = '';
      this.prestashop.pdomain = '';
    },
    loading(val) {
      if(val === true){
        this.$swal({
          title: 'Cargando',
          allowOutsideClick: false,
          onBeforeOpen: () => {
              this.$swal.showLoading()
          },
        });
      }
    }
  },

  methods: {
    async checkCredentials(domain, key) {
      try {
        const config = {
          method: 'POST',
          url: `${environment.url_automations}/check_cms_credentials`,
          data: {
            provider: "prestashop",
            credentials: {
              prestaDomain: domain,
              prestaKey: key
            }
          },
        }
        const res = await axios.request(config)
        return res.data
      } catch (error) {
        return false
      }
    },
    getCredentials() {
      let currentCredentials = JSON.parse(this.credentials);
      this.prestashop.pkey = currentCredentials.pkey;
      this.prestashop.pdomain = currentCredentials.pdomain;
    },

    async saveIntegration() {
      let status = true;
      this.loading = true;

      const { pkey, pdomain } = this.prestashop;

      if (pkey.length == 0) {
        this.error = 'error_key';
        this.loading = false;
      } else if (pdomain.length == 0) {
        this.error = 'error_empty';
        this.loading = false;
      } else if (!pdomain.includes("http")) {
        this.error = 'error_domain';
        this.loading = false;
      } else if (!this.edit) {
          const credValidation = await this.checkCredentials(pdomain, pkey)
          if(!credValidation) {
            this.$swal({
              icon: "error",
              title: "Credenciales no válidas",
            });
            this.loading = false
            return
          }
          dashboard_api
            .post("/user_credentials", {
              provider: this.selected,
              credentials: JSON.stringify(this.prestashop),
              status,
              bot_id: this.bot_id
            },
          ).then(resp => {
            // console.log('resp', resp.data);

            this.$swal({
              icon: "success",
              title: "PrestaShop integrado!",
              timer: 1000,
              showConfirmButton: false,
            });
            this.loading = false;
            this.error = '';
            // console.log('this.shopify.domain', this.shopify.domain);

            this.$emit('closeView');
            this.$emit('getIntegrations');
          })
          .catch(error => console.log('error->', error));
        } else {
          const credValidation = await this.checkCredentials(pdomain, pkey)
          if(!credValidation) {
            this.$swal({
              icon: "error",
              title: "Credenciales no válidas",
            });
            this.loading = false
            return
          }
          dashboard_api
            .put(`user_credentials/${this.integrationId}`, {
              credentials: JSON.stringify(this.prestashop)
            }).then((resp => {
              // console.log('resp edit jump', resp.data);

              this.$swal({
                icon: "success",
                title: "Integración modificada",
                timer: 1000,
                showConfirmButton: false,
              });
              this.loading = false;
              this.error = '';
              this.$emit('closeView');
              this.$emit('getIntegrations');
            }));
        }
    }
  }
};
</script>
