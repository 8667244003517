<template>
  <FadeTransition :key="my_register.step" :duration="200" origin="center top">
    <section class="proceso-registro">
      <FadeTransition :key="my_register.step" :duration="200" origin="center top">
        <FormRegistro
          type="register"
          v-if="my_register.step == 1 && logged == 'false'"
          :step="my_register.step"
          @nextStep="setStep"
          @sendChatbotWeb="web = $event"
        />
        <FirstBot
          v-if="my_register.step == 2 && logged == 'true'"
          :step="my_register.step"
          :web="web"
          @nextStep="setStep"
        />
        <IntegrationCMS
          v-if="my_register.step == 3"
          :step="my_register.step"
          :registerData="registerData"
          @stepBack="stepBack"
          @jumpStep="setStep"
          @nextStep="setStep"
        />
        <!-- <Subscription
          v-if="my_register.step == 3 && logged == 'true'"
          :step="my_register.step"
          @nextStep="setStep"
        /> -->
        <Implement
          v-if="my_register.step == 4"
          :step="my_register.step"
          @back="backEcommerceStep"
          @abort="navigateStep"
        />
      </FadeTransition>
    </section>
  </FadeTransition>
</template>

<script>
import { FadeTransition } from "vue2-transitions";
import { mapMutations, mapState } from "vuex";
import FormRegistro from "@/views/V2/Auth/components/Form_Registro";
import FirstBot from "@/views/V2/Auth/components/FirstBot";
import Implement from "@/views/V2/Auth/components/Implement";
import Subscription from "../../Auth/components/Subscrtiption";
import IntegrationCMS from "@/views/V2/Auth/components/IntegrationCMS";

export default {
  name: "register",
  components: {
    FormRegistro,
    FirstBot,
    Implement,
    FadeTransition,
    Subscription,
    IntegrationCMS
},
  data() {
    return {
      model: {
        name: "",
        email: "",
        password: "",
        password_confirmation: "",
        terms_confirmation: false
      },
      accountId: 0,
      my_register: {},
      web: "",
      validateToAbortView: false
    };
  },
  created() {
    this.my_register = this.registerData;

    // parametro de la url
    if (this.bot_id) {
      this.my_register.step =
        this.logged == "true"
          ? this.$router.push({
              name: "register",
              query: { activar: "activate", bot_id: this.bot_id }
            })
          : this.$router.push({
              name: "login",
              query: { login: "login", bot_id: this.bot_id }
            });
      this.my_register.step = 3;
    } else {
      this.my_register.step = this.logged == "true" ? this.my_register.step : 1;

      // validacion - usuario se intenta registrar pero ya tiene una cuenta logueada cierra las sesiones y lo devuelve al login
      if (this.logged == "true" && this.user.botIds.length > 0) {
        this.logout();
        this.$router.push("/login");
      }
    }
    this.setRegisterProcess(this.my_register);
  },
  mounted() {
    // smooth scroll desde una landing a otra
    if (this.$route.query.seccion) {
      this.scrollTo(2000);
    };
    this.userLeaveRegisterTab();
  },
  computed: {
    ...mapState(["logged", "registerData", "user", "ownerBots"]),

    bot_id() {
      return this.$route.query.bot_id;
    }
  },
  methods: {
    ...mapMutations(["setRegisterProcess", "cleanRegisterData", "cleanCheckoutData", "logout"]),

    userLeaveRegisterTab() {
      // Para que el user no abandone el proceso de registro
      if (this.registerData.step == 3) {
        window.addEventListener('beforeunload', (event) => {
          if (event.isTrusted) {
            console.log("event->", event);
            event.preventDefault();
            event.returnValue = '';
            this.cleanRegisterData();
            this.cleanCheckoutData();
            this.logout();
          }
        });
      }
    },
    backEcommerceStep() {
      this.ownerBots[0].has_skill_base = true;
      this.my_register.step--;
      this.setRegisterProcess(this.my_register);
      console.log("backEcommerceStep->", this.ownerBots[0]);
    },
    stepBack() {
      this.ownerBots[0].has_skill_base = false;
      this.setStep();
      console.log("stepBack->", this.ownerBots[0]);
    },
    setStep() {
      this.my_register.step++;
      this.setRegisterProcess(this.my_register);
      console.log("setStep->", this.ownerBots[0]);
    },
    // smooth scroll
    scrollTo(millis) {
      this.$scrollTo(`#${this.$route.query.seccion}`, millis);
    },
    navigateStep(val) {
      switch (val) {
        case 1:
          break;
        case 2:
          if (this.logged == "true") {
            this.my_register.step = val;
            this.setRegisterProcess(this.my_register);
          }
          break;
        case 3:
          if (this.my_register.bot) {
            this.my_register.step = val;
            this.setRegisterProcess(this.my_register);
          }
          break;
      }
    },
    setAccountId(id) {
      this.accountId = id;
    },
  },
  watch: {
    $route(to, from) {
      this.scrollTo(2000);
    }
  }
};
</script>

<style lang="scss" scoped>
.proceso-registro {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;

  @media (max-width: 991px) {
    height: 100%;
    margin: 4rem auto;
  }
}
</style>

