import * as echarts from 'echarts';

export default {
  methods: {
    questionBarChart(data, element, title) {
      let chartDom = document.getElementById(element);
      var myChart = echarts.init(chartDom, null, {
        renderer: 'svg',
      });

      myChart.setOption({
        title: {
          text: title,
        },
        legend: {},
        tooltip: {},
        dataset: {
          source: data,
        },
        grid: { containLabel: true },
        xAxis: { name: 'cantidad' },
        yAxis: { type: 'category' },
        toolbox: {
          feature: {
            saveAsImage: {},
            magicType: {
              type: ['line', 'bar', 'stack'],
            },
          },
        },
        series: [
          {
            type: 'bar',
            itemStyle: { color: "#2981EF" },
            encode: {
              x: 'Cantidad',
              y: 'Producto',
            },
          },
        ],
        textStyle: {
          fontFamily: "Manrope, sans-serif"
        },
      });
    }
  },
};
